import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Serving static files";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "serving-static-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#serving-static-files",
        "aria-label": "serving static files permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Serving static files`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#auto-generating-directory-listings"
        }}>{`Auto-generating directory listings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#specifying-additional-response-headers"
        }}>{`Specifying additional response headers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adjusting-static-file-cache"
        }}>{`Adjusting static file cache`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#serving-pre-compressed-files"
        }}>{`Serving pre-compressed files`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#serving-an-individual-file"
        }}>{`Serving an individual file`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-httpfile"
        }}>{`Configuring HttpFile`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#caching-httpfile"
        }}>{`Caching HttpFile`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#aggregating-httpfile"
        }}>{`Aggregating HttpFile`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#building-aggregatedhttpfile-from-httpdata"
        }}>{`Building AggregatedHttpFile from HttpData`}</a></li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`Use `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` to serve static files under a certain directory. `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` supports
`}<inlineCode parentName="p">{`GET`}</inlineCode>{` and `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` HTTP methods and will auto-fill `}<inlineCode parentName="p">{`Date`}</inlineCode>{`, `}<inlineCode parentName="p">{`Last-Modified`}</inlineCode>{`, `}<inlineCode parentName="p">{`ETag`}</inlineCode>{` and auto-detected
`}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` headers for you. It is also capable of sending a `}<inlineCode parentName="p">{`304 Not Modified`}</inlineCode>{` response based on
`}<inlineCode parentName="p">{`If-None-Match`}</inlineCode>{` and `}<inlineCode parentName="p">{`If-Modified-Since`}</inlineCode>{` header values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.file.FileService;

ServerBuilder sb = Server.builder();
sb.serviceUnder("/images/",
                FileService.of(Paths.get("/var/lib/www/images"));

// You can also serve the resources in the class path.
sb.serviceUnder("/resources",
                FileService.of(ClassLoader.getSystemClassLoader(),
                               "/com/example/resources"));
`}</code></pre>
    <h2 {...{
      "id": "auto-generating-directory-listings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#auto-generating-directory-listings",
        "aria-label": "auto generating directory listings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Auto-generating directory listings`}</h2>
    <p>{`You can configure `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` to generate the directory listing of the directories without
an `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file using the `}<inlineCode parentName="p">{`autoIndex(boolean)`}</inlineCode>{` method in `}<a parentName="p" {...{
        "href": "type://FileServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileServiceBuilder.html"
      }}>{`type://FileServiceBuilder`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.file.FileServiceBuilder;

FileServiceBuilder fsb =
        FileService.builder(Paths.get("/var/lib/www/images"));

// Enable auto-index.
fsb.autoIndex(true);
FileService fs = fsb.build();
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Be careful when you enable this feature in production environment; consider its security implications.`}</p>
    </Tip>
    <h2 {...{
      "id": "specifying-additional-response-headers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specifying-additional-response-headers",
        "aria-label": "specifying additional response headers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying additional response headers`}</h2>
    <p>{`You can specify additional response headers such as `}<inlineCode parentName="p">{`cache-control`}</inlineCode>{` and other custom headers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.ServerCacheControl;
import com.linecorp.armeria.common.ServerCacheControlBuilder;

FileServiceBuilder fsb =
        FileService.builder(Paths.get("/var/lib/www/images"));

// Specify cache control directives.
ServerCacheControl cc = ServerCacheControl.builder()
                                          .maxAgeSeconds(86400)
                                          .cachePublic()
                                          .build();
fsb.cacheControl(cc /* "max-age=86400, public" */);
// Specify a custom header.

fsb.setHeader("foo", "bar");
FileService fs = fsb.build();
`}</code></pre>
    <h2 {...{
      "id": "adjusting-static-file-cache",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adjusting-static-file-cache",
        "aria-label": "adjusting static file cache permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjusting static file cache`}</h2>
    <p>{`By default, `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` caches up to 1024 files whose length is less than or equal to
65,536 bytes. You can customize this behavior using `}<a parentName="p" {...{
        "href": "type://FileServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileServiceBuilder.html"
      }}>{`type://FileServiceBuilder`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`FileServiceBuilder fsb =
        FileService.builder(Paths.get("/var/lib/www/images"));

// Cache up to 4096 files.
fsb.entryCacheSpec("maximumSize=4096");
// Cache files whose length is less than or equal to 1 MiB.
fsb.maxCacheEntrySizeBytes(1048576);

FileService fs = fsb.build();
`}</code></pre>
    <p>{`The cache can be disabled by specifying `}<inlineCode parentName="p">{`0`}</inlineCode>{` for `}<inlineCode parentName="p">{`maxCacheEntries()`}</inlineCode>{`.
You can also specify a custom cache specification using `}<inlineCode parentName="p">{`entryCacheSpec()`}</inlineCode>{`,
as defined in `}<a parentName="p" {...{
        "href": "https://static.javadoc.io/com.github.ben-manes.caffeine/caffeine/2.8.0/com/github/benmanes/caffeine/cache/CaffeineSpec.html"
      }}>{`Caffeine documentation`}</a>{`.
Or, you can override the default cache specification of `}<inlineCode parentName="p">{`maximumSize=1024`}</inlineCode>{` using
the JVM property `}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.fileServiceCache=<spec>`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "serving-pre-compressed-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#serving-pre-compressed-files",
        "aria-label": "serving pre compressed files permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Serving pre-compressed files`}</h2>
    <p><a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` can be configured to serve a pre-compressed file based on the value of the
`}<inlineCode parentName="p">{`Accept-Encoding`}</inlineCode>{` header. For example, if a client sent the following HTTP request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`GET /index.html HTTP/1.1
Host: example.com
Accept-Encoding: gzip, identity
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` could look for `}<inlineCode parentName="p">{`/index.html.gz`}</inlineCode>{` first and send the following response with the
`}<inlineCode parentName="p">{`Content-Encoding: gzip`}</inlineCode>{` header if it exists:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`HTTP/1.1 200 OK
Host: example.com
Content-Encoding: gzip
Content-Type: text/html
...

<compressed content>
`}</code></pre>
    <p>{`If `}<inlineCode parentName="p">{`/index.html.gz`}</inlineCode>{` does not exist but `}<inlineCode parentName="p">{`/index.html`}</inlineCode>{` does, it would fall back on serving the uncompressed
content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-http"
      }}>{`HTTP/1.1 200 OK
Host: example.com
Content-Type: text/html
...

<uncompressed content>
`}</code></pre>
    <p>{`This behavior is enabled by calling `}<a parentName="p" {...{
        "href": "type://FileServiceBuilder#serveCompressedFiles(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileServiceBuilder.html#serveCompressedFiles(boolean)"
      }}>{`type://FileServiceBuilder#serveCompressedFiles(boolean)`}</a>{` with `}<inlineCode parentName="p">{`true`}</inlineCode>{`.
`}<inlineCode parentName="p">{`.gz`}</inlineCode>{` (gzip) and `}<inlineCode parentName="p">{`.br`}</inlineCode>{` (Brotli) files are supported currently.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`FileServiceBuilder fsb =
        FileService.builder(ClassLoader.getSystemClassLoader(),
                            "/com/example/resources");

// Enable serving pre-compressed files.
fsb.serveCompressedFiles(true);

FileService fs = fsb.build();
`}</code></pre>
    <p>{`If you set `}<a parentName="p" {...{
        "href": "type://FileServiceBuilder#autoDecompress(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileServiceBuilder.html#autoDecompress(boolean)"
      }}>{`type://FileServiceBuilder#autoDecompress(boolean)`}</a>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` automatically
decompresses the pre-compressed file when a client does not send an `}<inlineCode parentName="p">{`Accept-Encoding`}</inlineCode>{` header.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`FileServiceBuilder fsb = ...
fsb.serveCompressedFiles(true);
fsb.autoDecompress(true);

FileService fs = fsb.build();
`}</code></pre>
    <h2 {...{
      "id": "serving-an-individual-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#serving-an-individual-file",
        "aria-label": "serving an individual file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Serving an individual file`}</h2>
    <p>{`You can also serve an individual file using `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{`. Like `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{` does, `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{`
supports `}<inlineCode parentName="p">{`GET`}</inlineCode>{` and `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` HTTP methods and will auto-fill `}<inlineCode parentName="p">{`Date`}</inlineCode>{`, `}<inlineCode parentName="p">{`Last-Modified`}</inlineCode>{`, `}<inlineCode parentName="p">{`ETag`}</inlineCode>{` and
auto-detected `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` headers for you. It is also capable of sending a `}<inlineCode parentName="p">{`304 Not Modified`}</inlineCode>{` response
based on `}<inlineCode parentName="p">{`If-None-Match`}</inlineCode>{` and `}<inlineCode parentName="p">{`If-Modified-Since`}</inlineCode>{` header values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.file.HttpFile;

HttpFile favicon = HttpFile.of(Paths.get("/var/lib/www/favicon.ico"));
ServerBuilder sb = Server.builder();

// Serve the favicon.ico file by converting an HttpFile into a service.
sb.service("/favicon.ico", favicon.asService());
`}</code></pre>
    <p>{`For instance, it is possible to serve the same file (e.g. `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`) for all requests under a certain
path, which is useful when serving a frontend application with client-side routing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HttpFile index = HttpFile.of(Paths.get("/var/lib/www/index.html"));

ServerBuilder sb = Server.builder();
// Register the file service for assets.
sb.serviceUnder("/node_modules",
                FileService.of(Paths.get("/var/lib/www/node_modules")));
sb.serviceUnder("/static",
                FileService.of(Paths.get("/var/lib/www/static")));
// Register the fallback file service.
sb.serviceUnder("/", index.asService());
`}</code></pre>
    <p>{`You can also achieve the same behavior using `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service"
      }}>{`Annotated services`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Register the fallback file service.
sb.annotatedService(new Object() {
    final HttpFile index = HttpFile.of(Paths.get("/var/lib/www/index.html"));
    @Get
    @Head
    @Path("glob:/**")
    public HttpResponse getIndex(ServiceRequestContext ctx, HttpRequest req) {
        return index.asService().serve(ctx, req);
    }
});
`}</code></pre>
    <h2 {...{
      "id": "configuring-httpfile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-httpfile",
        "aria-label": "configuring httpfile permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring `}<inlineCode parentName="h2">{`HttpFile`}</inlineCode></h2>
    <p>{`An `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` can be configured to send different headers than the auto-filled ones using
`}<a parentName="p" {...{
        "href": "type://HttpFileBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFileBuilder.html"
      }}>{`type://HttpFileBuilder`}</a>{`. For example, you can:`}</p>
    <ul>
      <li parentName="ul">{`Disable auto-generation of `}<inlineCode parentName="li">{`Date`}</inlineCode>{`, `}<inlineCode parentName="li">{`Last-Modified`}</inlineCode>{`, `}<inlineCode parentName="li">{`Content-Type`}</inlineCode>{` and `}<inlineCode parentName="li">{`ETag`}</inlineCode>{` header.`}</li>
      <li parentName="ul">{`Customize how `}<inlineCode parentName="li">{`ETag`}</inlineCode>{` is calculated from metadata.`}</li>
      <li parentName="ul">{`Add or set additional custom HTTP headers.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.file.HttpFile;
import com.linecorp.armeria.server.file.HttpFileBuilder;

HttpFileBuilder fb = HttpFile.builder(Paths.get("/var/lib/www/index.html"));
// Disable the 'Date' header.
fb.date(false);
// Disable the 'Last-Modified' header.
fb.lastModified(false);
// Disable the 'ETag' header.
fb.entityTag(false);
// Disable the 'Content-Type' header.
fb.autoDetectContentType(false);
// Set the 'Content-Type' header manually.
fb.contentType("text/html; charset=EUC-KR");
// Set the 'Cache-Control' header.
fb.cacheControl(ServerCacheControl.REVALIDATED /* "no-cache" */);
// Set a custom header.
fb.setHeader("x-powered-by", "Armeria");
HttpFile f = fb.build();
`}</code></pre>
    <h2 {...{
      "id": "caching-httpfile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#caching-httpfile",
        "aria-label": "caching httpfile permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Caching `}<inlineCode parentName="h2">{`HttpFile`}</inlineCode></h2>
    <p>{`Unlike `}<a parentName="p" {...{
        "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
      }}>{`type://FileService`}</a>{`, `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` does not cache the file content.
Use `}<a parentName="p" {...{
        "href": "type://HttpFile#ofCached(HttpFile,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html#ofCached(com.linecorp.armeria.server.file.HttpFile,int)"
      }}>{`type://HttpFile#ofCached(HttpFile,int)`}</a>{` to enable content caching for an existing `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HttpFile uncachedFile = HttpFile.of(Paths.get("/var/lib/www/index.html"));
HttpFile cachedFile = HttpFile.ofCached(uncachedFile, 65536);
`}</code></pre>
    <p>{`Note that you need to specify the maximum allowed length of the cached content. In the above example, the file
will not be cached if the length of the file exceeds 65,536 bytes.`}</p>
    <h2 {...{
      "id": "aggregating-httpfile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#aggregating-httpfile",
        "aria-label": "aggregating httpfile permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Aggregating `}<inlineCode parentName="h2">{`HttpFile`}</inlineCode></h2>
    <p>{`An `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` usually does not store its content in memory but reads its content on demand, allowing you
to stream a potentially very large file. If you want to ensure the content of the file is kept in memory so
that file I/O does not occur on each retrieval, you can use the `}<a parentName="p" {...{
        "href": "type://HttpFile#aggregate(Executor):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html#aggregate(java.util.concurrent.Executor)"
      }}>{`type://HttpFile#aggregate(Executor)`}</a>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// You need to prepare an Executor which will be used for reading the file,
// because file I/O is often a blocking operation.
Executor ioExecutor = ...;

HttpFile file = HttpFile.of(Paths.get("/var/lib/www/img/logo.png");
CompletableFuture<AggregatedHttpFile> future = file.aggregate(ioExecutor);
AggregatedHttpFile aggregated = future.join();

// The content of the file can now be retrieved from memory.
HttpData content = aggregated.content();
`}</code></pre>
    <p>{`Note that an aggregated `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` is not linked in any way from the `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` it was aggregated
from, which means the content and attributes of the aggregated `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` does not change when the original
`}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{` changes. Use `}<a parentName="p" {...{
        "href": "type://HttpFile#ofCached(HttpFile,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html#ofCached(com.linecorp.armeria.server.file.HttpFile,int)"
      }}>{`type://HttpFile#ofCached(HttpFile,int)`}</a>{` instead if such behavior is necessary.`}</p>
    <h2 {...{
      "id": "building-aggregatedhttpfile-from-httpdata",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#building-aggregatedhttpfile-from-httpdata",
        "aria-label": "building aggregatedhttpfile from httpdata permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building `}<inlineCode parentName="h2">{`AggregatedHttpFile`}</inlineCode>{` from `}<inlineCode parentName="h2">{`HttpData`}</inlineCode></h2>
    <p>{`The content you need to serve is not always from an external resource but sometimes from memory, such as
`}<inlineCode parentName="p">{`byte[]`}</inlineCode>{` or `}<inlineCode parentName="p">{`String`}</inlineCode>{`. Use `}<a parentName="p" {...{
        "href": "type://HttpFile#of(HttpData):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html#of(com.linecorp.armeria.common.HttpData)"
      }}>{`type://HttpFile#of(HttpData)`}</a>{` or `}<a parentName="p" {...{
        "href": "type://HttpFile#builder(HttpData):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html#builder(com.linecorp.armeria.common.HttpData)"
      }}>{`type://HttpFile#builder(HttpData)`}</a>{` to build an
`}<a parentName="p" {...{
        "href": "type://AggregatedHttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/AggregatedHttpFile.html"
      }}>{`type://AggregatedHttpFile`}</a>{` from an in-memory resource:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Build from a byte array.
AggregatedHttpFile f1 = AggregatedHttpFile.of(HttpData.of(new byte[] { 1, 2, 3, 4 }));

// Build from a String.
AggregatedHttpFile f2 = AggregatedHttpFile.of(HttpData.ofUtf8("Hello, world!"));

// Build using a builder.
AggregatedHttpFile f3 =
    AggregatedHttpFile.builder(HttpData.ofAscii("Armeria"))
                      .lastModified(false)
                      .build();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      